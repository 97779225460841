<template>
  <div>
    <div class="editEpisodeArea">
      <h2>エピソードを入力する</h2>
      <div class="inner">
        <p class="inputTitle">
          <input
            v-model="episode.title"
            type="text"
            placeholder="タイトルを入力する"
            @keydown="onTitleKeyDown($event)"
          >
        </p>
        <p class="inputMessage">
          <input
            v-model="episode.message"
            type="text"
            placeholder="伝えたいことを入力する"
          >
        </p>
        <medium-editor
          ref="mediumEditor"
          :content="content"
          :options="options"
          @input="onChange"
          @uploaded="onChange"
          @upload-error="onUploadError"
        />
        <!-- /.editEpisodeArea -->
      </div>

      <div class="editSeasonArea">
        <h2>時期を入力する</h2>
        <div class="inner">
          <h3>いつ頃のエピソードですか?</h3>
          <ul class="season">
            <li>
              <label><input v-model="episode.time" value="1" type="radio"><span /></label>
              <p class="inputWrap year">
                <input v-model="episode.year" type="text" placeholder="0000">
              </p>
              <span>年</span>
              <p class="inputWrap">
                <input v-model="episode.month" type="text" placeholder="00">
              </p>
              <span>月</span>
              <p class="inputWrap">
                <input v-model="episode.day" type="text" placeholder="00">
              </p>
              <span>日</span>
            </li>
            <li class="free">
              <label><input v-model="episode.time" value="2" type="radio"><span /></label>
              <p class="inputWrap year">
                <input
                  v-model="episode.age"
                  type="text"
                  placeholder="2009年頃"
                >
              </p>
              <span>（例：1970年頃／幼少期／人生全般）</span>
            </li>
          </ul>
        </div>
        <!-- /.editSeasonArea -->
      </div>

      <div class="editHashArea">
        <h2>このエピソードの関連ワードを入力する（複数可）</h2>
        <div class="inner">
          <p class="inputWrap">
            <vue-tags-input
              v-model="episode.tag"
              placeholder="例）釣り"
              :tags="episode.tags"
              @tags-changed="onTagsChanged"
            >
              <div slot="tag-left">
                #
              </div>
            </vue-tags-input>
          </p>
        </div>
        <!-- /.editHashArea -->
      </div>

      <div class="editApprovalArea">
        <p>
          <label><input v-model="episode.allow_comments" type="checkbox"><span>コメントを許可する</span></label>
        </p>
        <!-- /.editApprovalArea -->
      </div>

      <div class="buttonArea">
        <div class="inner">
          <p class="private">
            <button
              type="submit"
              :disabled="formInvalid"
              @click="saveEpisode($event)"
            >
              保存する（非公開）
            </button>
          </p>
          <p class="submit">
            <button
              type="submit"
              :disabled="formInvalid"
              @click="postEpisode($event)"
            >
              公開する
            </button>
          </p>
        </div>
        <!-- /.buttonArea -->
      </div>
    </div>

    <!-- モーダル -->
    <modal name="form-submitted">
      <div
        id="modalCompleteSave"
        class="modal"
        style="display: block !important;"
      >
        <div class="modalOverlay" />
        <div class="modalInner">
          <dl>
            <dt>{{ modalTitle }}</dt>
            <dd>{{ modalText }}</dd>
          </dl>
          <div class="buttonArea">
            <p class="back">
              <router-link
                :to="{
                  name: 'user-show',
                  params: { id: getLoggedInUser.id }
                }"
              >
                コンテンツ一覧へ戻る
              </router-link>
            </p>
          </div>
          <p class="close">
            <a class="closeModal" @click="closeModal" />
          </p>
        </div>
      </div>
    </modal>

    <modal name="error">
      <div
        id="modalCompleteSave"
        class="modal"
        style="display: block !important;"
      >
        <div class="modalOverlay" />
        <div class="modalInner">
          <dl>
            <dt>エラー</dt>
            <dd>{{ modalError }}</dd>
          </dl>
          <div class="buttonArea">
            <p class="back">
              <a
                class="closeModal"
                @click="closeModal"
              >コンテンツの編集へ戻る</a>
            </p>
          </div>
          <p class="close">
            <a class="closeModal" @click="closeModal" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import NProgress from 'nprogress'
import VueTagsInput from '@johmun/vue-tags-input'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.min.css'
import 'vuejs-medium-editor-colorpicker/src/themes/default.css'
import * as requestOptions from '@/helpers/request-options.js'

export default {
  components: {
    VueTagsInput
  },
  props: {
    propEpisode: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      episode: null,
      isUploading: false,
      content: '',
      options: {
        imageDragging: false,
        placeholder: {
          text: '本文を入力',
          hideOnClick: true
        },
        toolbar: {
          buttons: [
            { name: 'bold', tagNames: ['b'], contentDefault: '<b>太字</b>' },
            { name: 'italic', tagNames: ['i'], contentDefault: '<i>斜体</i>' },
            { name: 'textcolor', contentDefault: '色' },
            {
              name: 'small',
              tagNames: ['small'],
              contentDefault: '<small>キャプション</small>'
            }
          ]
        },
        uploadUrl: requestOptions.baseUrl().baseURL + '/api/images',
        uploadUrlHeader: { Authorization: requestOptions.authHeader() },
        file_input_name: 'file',
        imgur: true
      },
      modalTitle: '',
      modalText: '',
      modalError: ''
    }
  },
  computed: {
    ...authComputed,
    formInvalid: function () {
      if (!this.episode.title || this.episode.title === '') {
        return true
      }

      if (!this.episode.message || this.episode.message === '') {
        // return true
      }

      if (!this.episode.description || this.episode.description === '') {
        return true
      }

      if (this.isUploading) {
        return true
      }
      return false
    },
    years: function () {
      var years = {}
      for (let i = 1920; i <= 2020; i++) {
        years[i] = i
      }
      return years
    },
    months: function () {
      var months = {}
      for (let i = 1; i <= 12; i++) {
        months[i] = i
      }
      return months
    },
    days: function () {
      var days = {}
      for (let i = 1; i <= 31; i++) {
        days[i] = i
      }
      return days
    },
    ages: function () {
      return {
        1: '幼少期',
        2: '学生時代',
        3: '社会人'
      }
    }
  },
  created () {
    this.episode = this.propEpisode
  },
  mounted () {
    if (this.episode.description !== '') {
      this.episode.description = this.episode.description + '<p><br></p>'
    }
    this.$refs.mediumEditor.editor.setContent(this.episode.description)

    var x = document.getElementsByClassName('ti-input')
    for (var i = 0, len = x.length | 0; i < len; i = (i + 1) | 0) {
      x[i].style.border = '0px'
    }
  },
  methods: {
    onTagsChanged (newTags) {
      var tags = []
      newTags.forEach(element => {
        element.text = element.text.replace(/\s/g, ' ')
        const elements = element.text.split(' ')
        elements.forEach(text => {
          if (text) {
            tags.push({ text })
          }
        })
      })
      return (this.episode.tags = tags)
    },
    onTitleKeyDown (e) {
      if (e.keyCode !== 13) {
        return
      }
      if (!this.$refs.mediumEditor.editor) {
        return
      }

      if (!this.$refs.mediumEditor.editor.elements) {
        return
      }

      if (this.$refs.mediumEditor.editor.elements.length < 1) {
        return
      }

      if (!this.$refs.mediumEditor.editor.elements[0].childNodes) {
        return
      }

      if (this.$refs.mediumEditor.editor.elements[0].childNodes.length < 1) {
        this.$refs.mediumEditor.editor.elements[0].focus()
        return
      }

      this.$refs.mediumEditor.editor.selectElement(
        this.$refs.mediumEditor.editor.elements[0].childNodes[
          this.$refs.mediumEditor.editor.elements[0].childNodes.length - 1
        ]
      )
    },
    closeModal () {
      this.$modal.hide('form-submitted')
      this.$modal.hide('error')
    },
    onChange (content) {
      this.episode.description = this.$refs.mediumEditor.editor.getContent()
    },
    onUploadError (error) {
      this.modalError = error
      this.$modal.show('error')
    },
    saveEpisode (event) {
      event.preventDefault()
      this.episode.display = false
      this.modalTitle = '保存完了'
      this.modalText = 'コンテンツを保存しました。'
      if (this.episode.uniqid !== '') {
        this.updateEpisode()
      } else {
        this.createEpisode()
      }
    },
    postEpisode (event) {
      event.preventDefault()
      this.episode.display = true
      this.modalTitle = '公開完了'
      this.modalText = 'コンテンツを公開しました。'
      if (this.episode.uniqid !== '') {
        this.updateEpisode()
      } else {
        this.createEpisode()
      }
    },
    createEpisode () {
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('episode/createEpisode', this.episode)
        .then(res => {
          this.isUploading = false
          this.$modal.show('form-submitted')
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
          this.$router.push({ name: 'logout' })
        })
    },
    updateEpisode () {
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('episode/updateEpisode', this.episode)
        .then(() => {
          this.isUploading = false
          this.$modal.show('form-submitted')
          NProgress.done()
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
          this.$router.push({ name: 'logout' })
        })
    }
  }
}
</script>

<style>
@import "../css/medium-editor.css";

.v--modal {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.vue-tags-input .ti-input {
  border: 0px;
}

.vue-tags-input.ti-focus .ti-input {
  border: 0px;
}
</style>
